.App-header {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  .header-intro {
    max-width: 800px;
    font-size: 15pt;
  }
}
