.replace-hair-section {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  .header-intro {
    max-width: 800px;
    font-size: 15pt;
  }

  .custom-upload {
    display: inline-block;
  }

  .upload-master-container {
    margin-top: 30px;
    
    .upload-container {
      width: 150px;
      height: 150px;
      border: 2px dashed #ccc;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  
    .upload-icon {
      font-size: 36px;
      margin-bottom: 8px;
      color: white;
    }
  
    .webcam-container {
      min-height: 640px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      width: 100%;
      .webcam-preview {
        border-radius: 20px;
      }
      
      .webcam-buttons {
        position: relative;
        bottom: 5%;
        display: flex;
        justify-content: space-around;
        width: 100px;
        margin: 30px;
      }
    }
  
    .upload-text {
      font-weight: bold;
      color: white;
    }
  }
  
  .captured-container {
    width: 50%;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .captured-image {
      margin-bottom: 30px;
      border-radius: 20px;
      max-width: 100%;
    }
  }
  .processing-container {
    width: 75%;
    min-height: 200px;
    margin: 50px 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }

  .processed-container {
    width: 75%;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .processed-image {
      margin-bottom: 30px;
      border-radius: 20px;
      min-height: 300px;
      max-width: 400px;
    }

    .processed-image-buttons {
      justify-content: center;
      gap: 40px;

      .change-config-button {
        background-color: lightblue;
      }
      .retake-button {
        background-color: lightgreen;
      }
    }

    .processed-image-tips {
      max-width: 400px;

      border: 2px solid white;
      border-radius: 20px;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      font-family: Arial, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      
      .processed-image-tips-title {
        margin: 10px;
      }
    }
  }
}

.retake-button-container {
  .retake-button {
    margin: 20px;
    max-width: 400px;
  }
}
