.find-barber-section {
  margin: 80px 0;
  color: white;

  .nearby-barber-title-divider {
    color: white;

    .nearby-barber-title {
      color: white;
    }
  }
  
  .google-map-container {
    margin: 20px;
    width: 800px;
    height: 400px;
    display: none;
  }
  
  .nearby-barber-finder {
    margin: 40px;
  }
  
  .nearby-barber-list {
    display: flex;
    justify-content: center;
  }
  
  .nearby-barber-row {
    width: 800px;
    .nearby-barber-card {
      transition: filter 0.3s, cursor 1s;
      height: 325px;
      margin: 10px;

      .image-container {
        width: 100%;
        height: 200px;
        background-size: cover;
        background-position: center;
      }
    }
    
    .nearby-barber-card:hover {
      cursor: pointer;
      filter: brightness(75%);
    }
  }
}

