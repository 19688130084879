.generate-hair-container-container {
  display: flex;
  justify-content: center;

  .generate-hair-container {
    background-color: #ffffff;
    padding: 20px;
    margin: 20px;
    border-radius: 20px;
    width: 700px;
  }
  
  .image-space {
    height: 400px;
    background-color: #f5f5f5;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .generate-submit-button {
    margin-top: 20px;
  }

  .image-filled {
    height: 400px;
    background-color: #f5f5f5;

    .image-filled-img {
      height: 400px;
    }
  }

}