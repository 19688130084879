.barber-info-modal {
  .barber-info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .value {
      margin-right: 15px;
    }
  }

  .barber-info-opening {
    color: green;
  }
  
  .barber-info-closed {
    color: red;
  }

  .barber-info-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
}