.zipcode-section-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .zipcode-section-form {
    background: white;
    padding: 20px;
    border-radius: 20px;
    margin: 20px;
    max-width: 400px;
  }

  .zipcode-section-address {
    margin: 20px;
  }
}